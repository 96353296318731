import { graphql } from "gatsby";
import { useContext } from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import Layout from "../components/layout";
import MainContent from "../components/mainContent";
import Navbar from "../components/Navbar";
import SEO from "../components/SEO";
import ThemeContext from "../contexts/ThemeContext";
import GlobalStyles from "../styles/GlobalStyles";

const BlogPostStyles = styled.article`
  background-color: var(--primary);
  border-radius: var(--borderRadius);
  padding: var(--main-content-padding);
  margin: var(--main-content-margin);

  h1 {
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
  h2,
  h3 {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  .blog-date {
    margin-bottom: 3rem;
  }

  img {
    display: block;
    width: 100%;
    max-width: 100%;
    background-color: var(--white);
    @media (min-width: 690px) {
      margin: 0 auto;
    }
  }

  .blog-title {
    margin-top: 0 !important;
    font-size: 2rem;
    font-family: Georgia, "Times New Roman", Times, serif;
    line-height: initial;
    @media (min-width: 690px) {
      font-size: 2.5rem;
    }
  }

  pre {
    width: 100%;
    background: var(--primary);
    border-radius: var(--borderRadius);
    padding: 1rem;
    overflow: scroll;
  }

  @media (min-width: 690px) {
    padding: 5rem 20%;
  }

  .task-list-item {
    list-style: none;
  }
`;

export default function Template({ data }) {
  const { theme } = useContext(ThemeContext);
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout>
      <GlobalStyles theme={theme} />
      <SEO
        title={frontmatter.title}
        titleTemplate={data?.site?.siteMetadata?.title}
        description={data?.site?.siteMetadata?.description}
      />
      <Navbar />
      <MainContent>
        <BlogPostStyles>
          <h1 className="blog-title">{frontmatter.title}</h1>
          <p className="blog-date">📆 {frontmatter.date}</p>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </BlogPostStyles>
      </MainContent>
      <Footer />
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "D MMMM, YYYY")
        slug
        title
      }
    }
  }
`;
